import { Map } from "immutable";
import { useMemo } from "react";

import {
  selectFilters,
  selectList,
  selectListIds,
  selectListSort,
  selectListHasMore,
  selectListSortDirection,
} from "selectors/pagination";
import { getActiveFilters } from "utils/search";

import useReduxState from "hooks/useReduxState";

export default function useList(key) {
  const pulledList = useReduxState(
    (state) => selectList(state, { key }),
    [key]
  );
  const sort = useReduxState((state) => selectListSort(state, key), [key]);
  const direction = useReduxState(
    (state) => selectListSortDirection(state, key),
    [key]
  );
  const filters = useReduxState(
    (state) => selectFilters(state, { key }),
    [key]
  );
  const ids = useReduxState((state) => selectListIds(state, key), [key]);
  const hasMore = useReduxState(
    (state) => selectListHasMore(state, { key }),
    [key]
  );

  const listExists = !!pulledList;
  const list = pulledList || Map();
  const listCount = ids.size;
  const currentPage = list
    ? Math.floor(list.get("offset", 0) / list.get("pageSize")) + 1
    : 0;
  const totalPages = Math.ceil(list.get("total") / list.get("pageSize"));

  return useMemo(
    () => ({
      listExists,
      list,
      ids,
      listCount,
      total: list.get("total"),
      totalIsLowerBound: list.get("totalIsLowerBound"),
      entity_type: list.get("entity_type"),
      currentPage,
      is404:
        !list.get("loading") && listCount === 0 && list.get("offset", 0) > 0,
      totalPages,
      hasMore:
        typeof currentPage === "boolean" && typeof totalPages === "boolean"
          ? currentPage < totalPages
          : hasMore,
      searchTerm: list.get("searchTerm"),
      loading: list.get("loading"),
      loaded: list.get("loaded"),
      sort,
      direction,
      staticFilters: list.get("staticFilters"),
      filters,
      availableFilters: list.get("availableFilters"),
      availableFiltersLoading: list.get("availableFiltersLoading"),
    }),
    [
      currentPage,
      ids,
      list,
      listCount,
      totalPages,
      listExists,
      sort,
      direction,
      filters,
      hasMore,
    ]
  );
}

export const useShowFiltersHeading = (listKey) => {
  const { availableFilters, availableFiltersLoading, filters } =
    useList(listKey);

  const activeFilters = getActiveFilters(filters, availableFilters);

  return !availableFiltersLoading && activeFilters && activeFilters.size > 0;
};
